export default function brand(dis, store, client) {
  async function load() {
    return client.get(`/api/brand`)
      .then(({ data }) => {
        data.fields = JSON.stringify(data.fields, null, 2)
        dis({ type: 'brand/set', payload: data })
        dis({ type: 'local/upsert', payload: { loaded: true, dirty: false } })
      })
  }
  
  async function commit() {
    const localData = store.getState().brand
    
    return client.put(`/api/brand`, { ...localData })
    .then(({ data }) => {
      dis({ type: 'brand/set', payload: data })
      dis({ type: 'local/upsert', payload: { error: null } })
    }).catch((e) => {
      if (e.response.status === 422) {
        dis({ type: 'local/upsert', payload: { error: 'Please check the details you entered' } })
      } else {
        dis({ type: 'local/upsert', payload: { error: 'Error saving' } })
      }
      console.error(e)
    })
  }
  
  async function upsert(data) {
    dis({ type: 'brand/upsert', payload: data })
    dis({ type: 'local/upsert', payload: { dirty: true }})
  }

  return { load, commit, upsert }
}
