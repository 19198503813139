import * as React from 'react'

function SvgOptIn(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.1344 15.3656C27.2506 15.4817 27.3428 15.6196 27.4057 15.7713C27.4686 15.9231 27.501 16.0857 27.501 16.25C27.501 16.4143 27.4686 16.5769 27.4057 16.7287C27.3428 16.8804 27.2506 17.0183 27.1344 17.1344L18.3844 25.8844C18.2683 26.0006 18.1304 26.0928 17.9787 26.1557C17.8269 26.2186 17.6643 26.251 17.5 26.251C17.3357 26.251 17.1731 26.2186 17.0213 26.1557C16.8696 26.0928 16.7317 26.0006 16.6156 25.8844L12.8656 22.1344C12.6311 21.8998 12.4993 21.5817 12.4993 21.25C12.4993 20.9183 12.6311 20.6002 12.8656 20.3656C13.1002 20.1311 13.4183 19.9993 13.75 19.9993C14.0817 19.9993 14.3998 20.1311 14.6344 20.3656L17.5 23.2328L25.3656 15.3656C25.4817 15.2494 25.6196 15.1572 25.7713 15.0943C25.9231 15.0314 26.0857 14.999 26.25 14.999C26.4143 14.999 26.5769 15.0314 26.7287 15.0943C26.8804 15.1572 27.0183 15.2494 27.1344 15.3656ZM35 7.5V32.5C35 33.163 34.7366 33.7989 34.2678 34.2678C33.7989 34.7366 33.163 35 32.5 35H7.5C6.83696 35 6.20107 34.7366 5.73223 34.2678C5.26339 33.7989 5 33.163 5 32.5V7.5C5 6.83696 5.26339 6.20107 5.73223 5.73223C6.20107 5.26339 6.83696 5 7.5 5H32.5C33.163 5 33.7989 5.26339 34.2678 5.73223C34.7366 6.20107 35 6.83696 35 7.5ZM32.5 32.5V7.5H7.5V32.5H32.5Z" fill="#F0C266"/>
    </svg>
  )
}

export default SvgOptIn
