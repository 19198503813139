import { useAct } from 'FlowBuilder/store'
import Checkbox from 'Shared/components/Checkbox'
import InlineCkEditor from 'Shared/components/CKEditor/InlineCkEditor'
import AsyncSelect from 'Shared/components/AsyncSelect'

export default function SurveyForm({ actionId }) {
  const act = useAct()

  const action = useSel(s => s.actions.entities[actionId])
  const config = action.config || { thankYouSubject: '', thankYouSenderId: '', thankYouEnabled: false }
  const [sourceMode, setSourceMode] = useState(false)

  function update(data) {
    act.actions.upsert(actionId, { config: { ...config, ...data } })
  }

  return <>
    <h2 className='no-margin-top'>Survey Thank You Responder</h2>
    <div className="flex column gap-10 no-margin-bottom">
      <div className='field'>
        <Checkbox
          label="Enabled"
          className="no-margin-bottom"
          checked={config.thankYouEnabled}
          onChange={(e) => update({ thankYouEnabled: e.target.checked})}
        />
      </div>
      <div className='field'>
        <label className="bold margin-bottom">Sender</label>
        <AsyncSelect
            entity="email_sender"
            value={config.thankYouSenderId}
            onChange={value => update({ thankYouSenderId: value })}
            classNamePrefix="email-sender-select"
            disabled={!config.thankYouEnabled}
          />
      </div>
      <div className='field'>
        <label className="bold">Subject</label>
        <input type='text' className='full-width margin-top' value={config.thankYouSubject} onChange={e => update({ thankYouSubject: e.target.value })} disabled={!config.thankYouEnabled} />
      </div>
      <div className='field'>
        <label className="bold">Body</label>
         <InlineCkEditor
            value={config.thankYouBody}
            sourceMode={sourceMode}
            setSourceMode={setSourceMode}
            onUpdate={value => update({ thankYouBody: value })}
            mergeTags={[]}
            disabled={!config.thankYouEnabled}
          />
      </div>
    </div>
  </>
}

