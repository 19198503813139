const columns = [
  {
    data: 'name',
    className: 'bold',
    render: (data, type, row, meta) => `
      <a href="/admin/settings/roles/${row.DT_RowId}">${data} ${row.system_role ? '<strong>(system role)</strong>' : ''}</a>
`,
  },
  { data: 'description' },
  { data: 'slug' },
]

const headers = [
  'Name',
  'Description',
  'Slug',
]

const filters = []

const sorters = []

const order = [[0, "asc"]]

export { columns, headers, filters, sorters, order }
