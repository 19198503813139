import './Text.scoped.scss'

export default function Checkbox({ response, setResponse, label, disabled = false }) {
  return (
    <div className="answers grow">
      <label className="flex-center">
        <input type="checkbox" onChange={(e) => setResponse(e.target.checked ? 'y' : 'n')} disabled={disabled} checked={response === 'y'} />
        {label}
      </label>
    </div>
  )
}

