import { useAct } from 'FlowBuilder/store'
import DraggableSelect from 'Shared/components/DraggableSelect'

export default function ShareActionForm({ actionId }) {
  const act = useAct()

  const action = useSel(s => s.actions.entities[s.local.selectedActionId])
  const config = action.config || { channels: [] }
  const value = config.channels || []

  const selectOptions = [
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'bluesky', label: 'Bluesky' },
  ]

  const optionsMap = _.keyBy(selectOptions, 'value')
  const selectedOptions = value.map(channelValue => optionsMap[channelValue])

  function update(data) {
    act.actions.upsert(actionId, { config: { ...config, channels: data } })
  }

  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(selectedOptions)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    
    update(items.map(x => x.value))
  }

  return <div className="box narrow">
    <h2 className='no-margin-top'>Share Page</h2>
    <div className='field'>
      <label className="bold">Share Channels</label>
      <DraggableSelect
        options={selectOptions}
        value={selectedOptions}
        onChange={o => update(o.map(x => x.value))}
        className="margin-top"
      />
    </div>
  </div>
}
