import './MultipleSelect.scoped.scss'
import Checkbox from 'Shared/components/Checkbox'

export default function MultipleSelect({ answers, response, setResponse, disabled = false }) {
  const localResponse = (Array.isArray(response) ? response : []).map((r) => +r)

  // Enable Next button without picking any answers
  useEffect(() => {
    if (setResponse) {
      setResponse(localResponse)
    }
  }, [])

  const handleChange = (event) => {
    const value = +event.target.value
    const responseArray = [...localResponse]
    const index = responseArray.indexOf(value)
    if (event.target.checked && index === -1) {
      responseArray.push(value)
    } else {
      if (index > -1) {
        responseArray.splice(index, 1)
      }
    }
    setResponse(responseArray)
  }

  return (
    <div className="answers">
      {answers.map((answer) => (
        <Checkbox
          value={answer.id}
          onChange={handleChange}
          disabled={disabled}
          checked={localResponse.includes(answer.id)}
          label={answer.answer}
          key={answer.digitOrder}
        />
      ))}
    </div>
  )
}
