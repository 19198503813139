function rawResponses() {
  const urlParams = new URLSearchParams(window.location.search)

  const responses = {}

  // Ensure no duplicates for keys representing items in an array
  const uniqueKeys = new Set(urlParams.keys())

  uniqueKeys.forEach((key) => {
    const matches = key.match(/^response_(\d+)(\[\])?/)

    // Ignore params not containing a survey response
    if (matches === null) {
      return
    }

    // If key ends with [] we know we have to store an 
    // array of values even if there is only one
    const isArrayKey = !!matches[2]

    // Get the response values
    const values = urlParams.getAll(key)
    responses[matches[1]] = isArrayKey ? values : values[0]
  })

  return responses
}

function isNumericString(str) {
  return typeof str === 'string' && str.trim() !== '' && !isNaN(str)
}

function answerToId(q, response) {
  if (isNumericString(response)) {
    return response
  }

  const answer = q.answers.find((a) => a.answer === response)
  return !!answer ? answer.id : answer
}


export default function responseFromSearchParam(q) {
  let response = rawResponses()[q.id]

  if (q.questionType === 'multiple_choice') {
    response = answerToId(q, response)
  }

  if (q.questionType === 'multiple_select' && Array.isArray(response)) {
    response = response.map((r) => answerToId(q, r))
  }

  return response
}

