import Step from './Step'
import InlineCkEditor from 'Shared/components/CKEditor/InlineCkEditor'

function InstructionsShortDescriptor({ complete, instructions }) {
  return <div>
    { complete && <div dangerouslySetInnerHTML={{ __html: instructions }} /> }
    { !complete && <p className='incomplete no-margin-bottom'>Describe and set up instructions for your campaign.</p> }
  </div>
}

export default function Instructions() {
  const act = useAct()
  const { instructions } = useSel(s => s.campaign)
  const complete = !!instructions && instructions != '<p></p>' // default empty string given by editor
  const { mergeTags } = useSel(s => s.campaignBuilder)

  function onChange(html) {
    act.campaign.upsert({ instructions: html })
  }

  return <Step
    name='Instructions'
    editor='instructions'
    complete={complete}
    shortDescriptor={ <InstructionsShortDescriptor complete={complete} instructions={instructions} /> }
  >
    <InlineCkEditor
      value={instructions}
      sourceMode={false}
      setSourceMode={() => {}}
      onUpdate={onChange}
      mergeTags={mergeTags}
      enableSourceMode={false}
    />
  </Step>
}
