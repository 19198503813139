import Loading from 'Shared/components/Loading'
import ColorField from 'Shared/components/ColorField/ColorField'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'
import useAct from '../useAct'
import FontPicker from "font-picker-react";
import './Form.scoped.scss'

export default function Form() {
  const act = useAct()
  
  const {
    primaryColor,
    secondaryColor,
    logoLightBg,
    logoDarkBg,
    logoSquare,
    favicon,
    headerFont,
    bodyFont
  } = useSel((s) => s.brand)
  
  const { loaded, error, dirty } = useSel((s) => s.local)
  
  useEffect(() => {
    if (!loaded) {
      act.brand.load()
    }
  }, [loaded])

  if (!loaded) return <Loading />
  
 
  return <div>
    <h1>Brand Settings</h1>

    <div className='box'>
      <h2 className='no-margin-top'>Colours</h2>
      <div className="fields-container form">
        <ColorField
          color={primaryColor || '#FFFFFF'}
          onChange={(val) => act.brand.upsert({primaryColor: val})}
          label={'Primary Colour'}
        />
        <ColorField
          color={secondaryColor || '#FFFFFF'}
          onChange={(val) => act.brand.upsert({secondaryColor: val})}
          label={'Secondary Colour'}
        />
      </div>
    </div>

    <div className='box margin-top form'>
      <h2 className='no-margin-top'>Logos</h2>
      <div className="field logo-field margin-top half">
        <label>Logo (Light Background)</label>
        <div className="flex-center gap-10">
          <div>
            <FileUploader onUploaded={(val) => act.brand.upsert({logoLightBg: val[0] })} />
          </div>
          <div className="logo-preview light">
            {logoLightBg && <img src={`/rails/active_storage/blobs/${logoLightBg}/image.png`} />}
          </div>
        </div>
        <p className="help margin-top half">Recommended aspect ratio of 5:1 and minimum width of 400px</p>
      </div>
      <div className="field logo-field margin-top half">
        <label>Logo (Dark Background)</label>
        <div className="flex-center gap-10">
          <div>
            <FileUploader onUploaded={(val) => act.brand.upsert({logoDarkBg: val[0] })} />
          </div>
          <div className="logo-preview dark">
            {logoDarkBg && <img src={`/rails/active_storage/blobs/${logoDarkBg}/image.png`} />}
          </div>
        </div>
        <p className="help margin-top half">Recommended aspect ratio of 5:1 and minimum width of 400px</p>
      </div>
      <div className="field logo-field margin-top half">
        <label>Logo (Square)</label>
        <div className="flex-center gap-10">
          <div>
            <FileUploader onUploaded={(val) => act.brand.upsert({logoSquare: val[0] })} />
          </div>
          <div className="logo-preview square">
            {logoSquare && <img src={`/rails/active_storage/blobs/${logoSquare}/image.png`} />}
          </div>
        </div>
        <p className="help margin-top half">Must be square (1:1 aspect ratio). Recommended minimum 400x400px dimensions.</p>
      </div>
      <div className="field logo-field margin-top half">
        <label>Favicon</label>
        <div className="flex-center gap-10">
          <div>
            <FileUploader onUploaded={(val) => act.brand.upsert({favicon: val[0] })} />
          </div>
          <div className="logo-preview favicon">
            {favicon && <img src={`/rails/active_storage/blobs/${favicon}/image.png`} />}
          </div>
        </div>
        <p className="help margin-top half">Must be square (1:1 aspect ratio). Recommended minimum 48x48px dimensions.</p>
      </div>
    </div>

    <div className='box margin-top'>
      <h2 className='no-margin-top'>Fonts</h2>
      <div className="fields-container form">
        <div className="field">
          <label className="margin-bottom half">Header Font</label>
          <FontPicker
              apiKey={window.ENV.GOOGLE_FONTS_API_KEY}
              activeFontFamily={headerFont}
              onChange={(val) => act.brand.upsert({headerFont: val.family })}
              limit={500}
              pickerId="header"
            />
        </div>
        <div className="field">
          <label className="margin-bottom half">Paragraph Font</label>
          <FontPicker
              apiKey={window.ENV.GOOGLE_FONTS_API_KEY}
              activeFontFamily={bodyFont}
              onChange={(val) => act.brand.upsert({bodyFont: val.family })}
              limit={500}
              pickerId="body"
            />
        </div>
      </div>
      <div className="font-preview">
        {headerFont && <h1 className="apply-font-header margin-bottom half larger no-margin-top">Font Preview (Header - {headerFont})</h1>}
        {bodyFont && <p className="apply-font-body no-margin-top">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. (Paragraph - {bodyFont})</p>}
      </div>
    </div>
    
    <div className="actions margin-top">
      <ServerWaitButton className='button primary' onClick={() => act.brand.commit()} disabled={!dirty}>Save</ServerWaitButton>
      <ServerWaitButton className='margin-left half button red' onClick={() => act.brand.load()} disabled={!dirty}>Cancel</ServerWaitButton>
      { error ? (
        <div className="error">
          <p className="error">{error}</p>
        </div>
      ) : <></>}
    </div>

  </div>
};
