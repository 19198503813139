import { useAct } from 'FlowBuilder/store'
import './Sidebar.scoped.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function SurveyAction({ action }) {
  const act = useAct()
  const { name, kind } = action
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selectedPageNumber = useSel(s => s.local.selectedPageNumber)
  const selected = selectedActionId === action.id
  const questions = useSel(s => _.values(s.questions.entities).filter(q => q.active && q.surveyId === action.surveyId))
  const pageCount = useSel(s => s.builder.pageCount)
  const navItem = useSel(s => s.local.navItem)

  const questionsByPage = _.groupBy(_.orderBy(questions, 'digitOrder'), 'pageNumber');

  function addPage(index) {
    act.surveys.addPage({ insertIndex: index, surveyId: action.surveyId })
  }

  const onDragEnd = (result) => {
    const { draggableId, destination } = result;
    if (!destination) return;

    const isNumericString = (str) => /^\d+$/.test(str);

    const questionId = isNumericString(draggableId) ? parseInt(draggableId) : draggableId // can be uuid
    const destPageNumber = parseInt(destination.droppableId);
    const destDigitOrder = destination.index + 1; // 1-indexed

    act.questions.moveQuestion(questionId, destPageNumber, destDigitOrder);
  };
  
  function handleClick(e) {
    e.stopPropagation()
    act.builder.selectAction(action.id, { pageNumber: selectedPageNumber || 1 })
  }

  return (
    <div className={`box pages cursor-pointer ${selected ? 'dark-border' : ''} margin-bottom`} onClick={handleClick}>
      <div className="row justify-space-between">
        <p className='title margin-bottom'>Survey</p>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          {(new Array(pageCount)).fill().map((_, i) => (
            <Page
              key={i}
              pageNumber={i + 1}
              questions={questionsByPage[i + 1] || []}
              actionId={action.id}
              surveyId={action.surveyId}
              selectedPageNumber={selected ? selectedPageNumber : null}
            />
          ))}
        </div>
      </DragDropContext>
      {navItem === 'build' && (
        <div>
          <button className='button naked add-page' onClick={() => addPage(pageCount)}>
            <SvgIconsPlus /> Add page
          </button>
        </div>
      )}
    </div>
  )
}

function Page({ pageNumber, questions, actionId, surveyId, selectedPageNumber }) {
  const act = useAct();
  const navItem = useSel(s => s.local.navItem);

  function removePage() {
    act.surveys.removePage({ pageNumber, surveyId });
  }

  function handleClick(e) {
    e.stopPropagation()
    act.builder.selectAction(actionId, { pageNumber })
  }

  return (
    <div className={`box page ${selectedPageNumber === pageNumber ? 'dark-border' : ''}`} onClick={handleClick}>
      <div className='row justify-space-between'>
        <div className='page-number'><strong>Page {pageNumber}</strong></div>
        {navItem === 'build' && <div onClick={removePage}><SvgIconsDelete /></div>}
      </div>
      <Droppable droppableId={pageNumber.toString()}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {questions.map((q, index) => (
              <Question
                key={q.id}
                question={q}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {navItem === 'build' && (
        <div>
          <button className='button naked add-question' onClick={() => act.local.setModal({ kind: 'addQuestion', pageNumber })}>
            <SvgIconsPlus /> Add question
          </button>
        </div>
      )}
    </div>
  );
}

function Question({ question, index }) {
  const act = useAct();
  const navItem = useSel(s => s.local.navItem);

  if (navItem === 'build') {
    return <Draggable draggableId={String(question.id)} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="question row"
        >
          <div {...provided.dragHandleProps} className="drag-handle">⣿</div>
          <div className='question-text'>
            {_.truncate(question.question || '', { length: 22 })}
          </div>
          <div className="delete-question" onClick={() => act.questions.markInactive(question.id)}>
            <SvgIconsDelete />
          </div>
        </div>
      )}
    </Draggable>
  } else {
    return (
      <div className='question row'>
        <div className='question-text'>
          {_.truncate(question.question || '', { length: 22 })}
        </div>
      </div>
    );
  }
}
