import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import './PageContainer.scoped.scss'
import useAct from '../useAct'
import Stat from 'Shared/components/Stat'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import SimplePaginator from 'Shared/components/SimplePaginator'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'
import QuestionStats from 'Shared/Surveys/components/QuestionStats'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import restClient from 'Shared/hooks/restClient'
import CloseModal from './CloseModal.jsx'
import { ManageMenuItem } from 'Shared/components/ManageMenu';
import ManageMenuComponent from 'Shared/components/ManageMenu/ManageMenuComponent';
import CopyToClipboard from 'Shared/components/CopyToClipboard'

import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'

const items = [
  { name: 'Status', tag: 'status' },
  { name: 'Analytics', tag: 'analytics' },
  { name: 'Page', tag: 'page' },
]

const total = 100 // Total number of submissions - hardcoded for now

export default function PageContainer({ flowId, flowName, surveyId }) {
  const act = useAct()
  const scriptActions = useScriptActions()
  const [showCloseModal, setShowCloseModal] = useState(false)
  const { stats, status, slug, publicUrl } = useSel((s) => s.flow)
  const flowTemplate = useSel((s) => s.flow.flowTemplate)
  const [overviewPage, setOverviewPage] = useState(1)
  const size = useWindowSize()
  const [exportPassword, setExportPassword] = useState(null)

  const doExport = () => {
    restClient.post(`/api/surveys/${surveyId}/export`).then((response) => {
      setExportPassword(response.data.password)
    })
  }

  useEffect(() => {
    if (flowId) {
      act.flow.load(flowId)
      act.flow.loadStats(flowId)
    }
  }, [flowId])

  useEffect(() => {
    if (surveyId) {
      scriptActions.scriptBuilder.load(surveyId)
      const questionInterval = setInterval(() => {
        scriptActions.scriptBuilder.load(surveyId)
      }, 5000)
      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  const stepsData = (stats?.steps || []).map((step) => {
    return {
      name: step.kind,
      amountLeft: step.total - step.completed,
      completed: step.completed,
      total: step.total
    }
  })

  const closeFlow = (redirectUrl) => {
    act.flow.close(flowId, redirectUrl)
    setShowCloseModal(false)
  }

  const reopenFlow = () => {
    act.flow.reopen(flowId)
  }

  const menuItems: ManageMenuItem[] = useMemo(() => [
    {
      title: (
        <span className="flex items-center">Edit</span>
      ),
      onClick: () => {
        window.location.href = `/admin/flows/${flowId}/build`;
      },
    },
    {
      title: (
        <span className="flex items-center">Export results</span>
      ),
      onClick: () => doExport('default'),
    },
  ], [flowId]);

  return (
  <>

    <div className="header flow">
      <a className='back' href="/admin/flows"></a>
      <div className="name-container">
        <h1>{flowName}</h1>
        <p style={{textTransform: 'uppercase'}}>{flowTemplate?.name}</p>
        <div className="actions">
          <a className='button secondary' target='_blank' href={`${publicUrl}?forget=1`} rel="noreferrer">View</a>
          <ManageMenuComponent
            items={menuItems}
            components={[]}
            buttonType='primary'
          />
        </div>

    <div className='url'>
     <span>{publicUrl}</span>
     <div className='copier'><CopyToClipboard text="Copy" url={publicUrl} /></div>
    </div>

      </div>
    </div>

    <div className='flow-overview margin-top double'>
      <div className="left">
        {size.width >= MOBILE_SCREEN_WIDTH && <FloatingMenu items={items} />}
        {size.width < MOBILE_SCREEN_WIDTH && (
          <SimplePaginator
            totalPages={3}
            currentPage={overviewPage}
            onChange={setOverviewPage}
            labels={items.map((item) => item.name)}
            classes="margin-bottom"
          />
        )}
      </div>
      <div className='right'>
        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 1) && (
          <div className='box gap' id='status'>
            <div className="poster-container">
              <div className="poster">
                { slug ? (
                  <iframe
                    src={`/target/flows/${slug}`}
                    title="Embedded Page"
                    className="iframe"
                    loading="lazy"
                  ></iframe>
                ) : (
                  <SvgCircLoading />
                )}
              </div>
            </div>
            <div className='main'>
              <div className='header row'>
                <h1 className='no-margin-top'>Page { status === 'closed' ? 'Closed' : status ? 'Active' : '' }</h1>
                <div className='actions'>
                  { status !== 'closed' &&
                    <button className="button red primary" onClick={() => setShowCloseModal(true)}>
                      Close
                    </button>
                  }
                  { status === 'closed' &&
                    <button className="button green primary" onClick={reopenFlow}>
                      Re-open
                    </button>
                  }
                  <CloseModal
                    hide={() => setShowCloseModal(false)}
                    onSubmit={closeFlow}
                    open={showCloseModal}
                  />
                </div>
              </div>
              <div className={`progress-bar margin-top double ${status === 'paused' ? 'paused' : ''}`}>
                {(stats?.flowsCompleted || 0) < total && <>
                  <div className="progress-bar-bar-container">
                    <div className="bar" style={{ width: `${percentage((stats?.flowsCompleted || 0), total)}%` }}></div>
                  </div>
                  <div className="progress-bar-info margin-top">
                    <div className="progress-bar-info-block --left">{(stats?.flowsCompleted || 0).toLocaleString()} out of {total.toLocaleString()} submissions received</div>
                    <div className="progress-bar-info-block --right">{percentage((stats?.flowsCompleted || 0), total)}%</div>
                  </div>
                </>}
              </div>
            </div>
          </div>
        )}
        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 2) && (
          <div className='box grid margin-top double no-side-padding' id='analytics'>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Started page`}
                primary={stats?.flowsStarted || 0}
              />
            </div>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Completed page`}
                primary={stats?.flowsCompleted || 0}
              />
            </div>
            <div className="stat-container">
              <Stat
                borderBottom={false}
                size={'vertical'}
                label={`Completion rate`}
                primary={`${percentage(stats?.flowsCompleted || 0, stats?.flowsStarted || 0)}%`}
              />
            </div>
          </div>
        )}

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 2) && (
          <QuestionStats className="margin-top" />
        )}

        {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 3) && (
          <div className='box flex column margin-top double' id='page'>
            <h1 className='no-margin-top'>Page Steps</h1>
            <div className="step-container">
              <div className="info">
                <div className="example-box">
                  <span className="color-swatch" style={{ background: '#00F399' }}></span>
                  <p>Completed</p>
                </div>
                <div className="example-box">
                  <span className="color-swatch" style={{ background: 'rgba(24, 24, 91, 0.2)' }}></span>
                  <p>Started</p>
                </div>
              </div>
              <div className="data">
                <div className="chart-keys">
                  {stepsData.map((step, index) => (
                    <p key={index}>{step.name}</p>
                  ))}
                </div>
                <ResponsiveContainer width="100%" height={stepsData.length * 42}>
                  <BarChart
                    layout="vertical"
                    data={stepsData}
                    barSize={20}
                  >
                    <YAxis dataKey="name" type="category" width={100} hide={true} />
                    <XAxis type="number" hide={true} />
                    <Bar dataKey="completed" stackId="a" fill="#00F399" />
                    <Bar dataKey="amountLeft" stackId="a" fill="rgba(24, 24, 91, 0.1)" />
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-data">
                  {stepsData.map((step, index) => (
                    <p key={index}>{step.completed}/{step.total}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

    <ExportConfirmationModal
      exportPassword={exportPassword}
      setExportPassword={setExportPassword}
      entityName="flow" />
    </>
  )
}

function percentage(partialValue, totalValue) {
  if (partialValue === 0 || totalValue === 0) {
    return 0
  }
  return Math.round((100 * partialValue) / totalValue)
}
