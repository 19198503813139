/* eslint-disable react/prop-types */
import { FC, ReactNode } from 'react'
import { ManageMenuItem } from '.'
import OutsideAlerter from '../OutsideAlerter'
import { Container as ModalContainer } from 'react-modal-promise'
import classNames from 'classnames'
import PageAction from '../PageAction'
import './Menu.scoped.scss'

const ManageMenuComponent: FC<{
  components: ReactNode[]
  items: ManageMenuItem[]
  title?: ReactNode
  buttonType?: string
}> = ({ items, components, title = 'Manage', buttonType = 'secondary' }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  return (
    <OutsideAlerter onClickOutside={() => setIsDropdownVisible(false)}>
      <ModalContainer />
      {components ?? null}
      <div
        className="manage-menu"
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        <div>
          <button
            className={`manage-menu-button button ${buttonType}`}
            type="button"
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          >
            <div className="spacing-horizontal-1 button-title">{title}</div>
            <SvgChevronDown className="smaller margin-left" />
          </button>
        </div>
        {isDropdownVisible && (
          <div className="popup popup-list manage-menu-dropdown">
            <div className="list">
              {items.map(({ title, className, ...rest }, i) => (
                <div className="item" key={`${title}}__${i}`}>
                  <PageAction
                    className={classNames('manage-menu-dropdown-button', className)}
                    {...rest}
                  >
                    {title}
                  </PageAction>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideAlerter>
  )
}
export default ManageMenuComponent
