import './MultipleChoice.scoped.scss'
import Radio from 'Shared/components/Radio'

export default function MultipleChoice({ answers, response, setResponse, name, disabled = false }) {
  const handleChange = (event) => {
    if (event.target.checked) {
      setResponse(+event.target.value);
    }
  }

  return (
    <div className="answers">
      {answers.map((answer) => (
        <Radio
          value={answer.id}
          onChange={handleChange}
          disabled={disabled}
          checked={+response === answer.id}
          label={answer.answer}
          key={answer.digitOrder}
        />
      ))}
    </div>
  )
}
