import './Template.scoped.scss'

const getIcon = (iconType) => {
  switch (iconType) {
    case "petition":
      return <SvgIconsSignature />
    case "survey":
      return <SvgIconsCircleQuestion />
    default:
      return null
  }
}

const Template = ({ templates, setTemplate }) => {
  return (
    <>
    <div className="header margin-left">
      <h2 className="margin-bottom">Choose Template</h2>
    </div>
      <div className='template'>
        {templates.map(template => (
          <a className="card large selectable" onClick={() => setTemplate(template)} key={template.id}>
            {getIcon(template.icon)}
            <h3>{template.name}</h3>
            <p>{template.description}</p>
          </a>
        ))}
        {templates.length === 0 && <p className="small dark-grey margin-left no-margin-top">No page templates setup</p>}
      </div>
    </>
  )
}

export default Template
