import { Liquid } from 'liquidjs'

const MOVEMENT_PAGE_LOADED = 'movementPageLoaded';

export default function TemplateRender({ htmlContent, context = {}, componentMap, edit = false }) {
  // Reference to hold component target nodes
  const [componentTargets, setComponentTargets] = useState([]);
  const [editTargets, setEditTargets] = useState([]);
  // Reference to hold the container element
  const containerRef = useRef(null);
  const templateFieldsConfig = useSel(s => s.builder?.flow?.template?.fields || [])

  useEffect(() => {
    // Create temporary container to parse HTML
    async function processTemplate() {
      try {
        // Initialize Liquid engine
        const engine = new Liquid();

        // Render the template with the provided context
        if (edit) {
          var templateVars = context.template || {}
          var newContext = {
            ...context,
            template: _.mapValues(templateVars, (value, slug) => {
              const fieldConfig = templateFieldsConfig.find(f => f.slug === _.snakeCase(slug))
              // if a setup field then don't have inline editing
              if (fieldConfig?.setup) {
                return value
              } else {
                return `<editfield id="${_.escape(slug)}"></editfield>`
              }
            })
          }
          var renderedContent = await engine.parseAndRender(htmlContent, newContext);
        } else {
          var renderedContent = await engine.parseAndRender(htmlContent, context);
        }

        // Create temporary container to parse HTML
        const temp = document.createElement('div');
        temp.innerHTML = renderedContent;

        // ensure scripts inside templates run
        const scripts = temp.getElementsByTagName('script');
        Array.from(scripts).forEach(oldScript => {
          const newScript = document.createElement('script');
          Array.from(oldScript.attributes).forEach(attr => {
            newScript.setAttribute(attr.name, attr.value);
          });
          newScript.textContent = oldScript.textContent;
          oldScript.parentNode.replaceChild(newScript, oldScript);
        });

        const parent = containerRef.current.parentElement;

        // Clear any existing content
        while (containerRef.current.previousSibling) {
          parent.removeChild(containerRef.current.previousSibling);
        }

        // Insert all elements before our container div
        while (temp.firstChild) {
          parent.insertBefore(temp.firstChild, containerRef.current);
        }

        // Find component targets in the parent
        const targets = parent.querySelectorAll('surveyform');
        setComponentTargets(Array.from(targets));

        // Dispatch custom event after content is added
        const event = new CustomEvent(MOVEMENT_PAGE_LOADED, {
          detail: {
            content: renderedContent,
            timestamp: new Date(),
            parent: parent
          },
          bubbles: true  // This allows the event to bubble up through the DOM
        });
        containerRef.current.dispatchEvent(event);

        if (!edit) return;

        const editT = _.map(newContext.template, (value, slug) => {
          const targets = parent.querySelectorAll(`editfield[id="${slug}"]`);
          return targets ? { targets: Array.from(targets), slug } : null;
        })
        const editTNoNulls = _.compact(editT);
        setEditTargets(editTNoNulls);
      } catch (error) {
        console.error('Error processing template:', error);
      }
    }

    processTemplate();

    // Cleanup
    return () => {
      setComponentTargets([]);
      setEditTargets([]);
      while (containerRef.current.previousSibling) {
        const parent = containerRef.current.parentElement;
        parent.removeChild(containerRef.current.previousSibling);
      }
    };
  }, [htmlContent]) // Only run when the HTML content changes

  return (
    <>
      {/* Hidden marker element */}
      <div ref={containerRef} style={{ display: 'none' }} />
      {componentTargets.map((target, index) =>
        ReactDOM.createPortal(
          componentMap.TakeSurveyForm,
          target,
          `take-survey-form-${index}`
        )
      )}
      {editTargets.length > 0 && editTargets.map(({ targets, slug }, index) => {
        if (!targets || targets.length === 0) return null;

        return targets.map((target, index) => {
          return ReactDOM.createPortal(
            <componentMap.FieldEdit slug={slug} />,
            target,
            `field-edit-${slug}-${index}`
          )
        })
      })}
    </>
  );
}
