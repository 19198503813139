import store from 'Shared/store'
import { Provider } from 'react-redux'
import Menu from './Menu'
import ShareButton from 'AccessControl/components/ShareButton'

function startText(campaignType) {
  if (campaignType === 'phone') return 'Start calling'
  if (campaignType === 'sms_conversations') return 'Start replying'

  return ''
}

export default function CampaignActions({ id, campaignType, slug, status }) {
  const isShareCampaign = _.includes(['phone', 'sms_conversations'], campaignType)
  const enableDistributedCampaigning = window.ENV.FEATURES['distributed_campaigning']

  return (
    <Provider store={store}>
      <div className="actions">
        <Menu campaignId={id} campaignType={campaignType} slug={slug} />
        {isShareCampaign && (
          <a
            className={`button primary ${status === 'paused' ? 'disabled' : ''}`}
            href={`/campaigns/${slug}`}
          >
            {startText(campaignType)}
          </a>
        )}
        { (enableDistributedCampaigning && campaignType === 'phone') && <ShareButton entityType="campaign" entityId={id} entityName="Campaign" /> }
      </div>
    </Provider>
  )
}
