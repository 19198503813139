import AsyncSelect from 'Shared/components/AsyncSelect'

export default function FlowActionSelect({
  value, onChange, flowId
}) {

  if(!flowId) {
    return <p>Please choose a page first</p>
  }

  const endpoint = `/api/flows/${flowId}/actions`

  return (
    <AsyncSelect
      value={value}
      baseEndpoint={endpoint}
      onChange={onChange}
    />
  )
}

