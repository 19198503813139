import PillEditor from 'Shared/components/PillEditor/PillEditor'
import SimpleCharacterCounter from 'Shared/components/SimpleCharacterCounter'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'

import { useEffect } from 'react'
import Step from './Step'
import SmsPreview from './SmsPreview'
import './TextMessageContent.scoped.scss'
import './WhatsAppContent.scoped.scss'
import { MAX_WHATSAPP_MESSAGE_LENGTH } from 'Shared/constants'

function WhatsAppContentShortDescriptor({ complete, body }) {
  return (
    <div>
      {complete && (
        <p className="no-margin-bottom" dangerouslySetInnerHTML={{ __html: body }}></p>
      )}
      {!complete && (
        <p className="incomplete no-margin-bottom">What does the WhatsApp message say?</p>
      )}
    </div>
  )
}

export default function WhatsAppContent() {
  const act = useAct()
  const {
    mergeTags,
    whatsAppTemplates,
    currentEditor,
    renderedContent,
    templateRenderError,
  } = useSel((s) => s.campaignBuilder)
  const { whatsappTemplateId, interpolations, defaultInterpolations } = useSel(
    (s) => s.campaign.textMessageBlast
  )

  const activeWhatsAppTemplates = whatsAppTemplates.filter((t) => t.discardedAt === null)
  const whatsappTemplate = whatsAppTemplates.find((t) => t.id === whatsappTemplateId)
  const editing = currentEditor === 'text'
  const complete = whatsappTemplateId && _.every(_.values(interpolations), (v) => !!v)
  const actions = (() => {
    if (
      whatsappTemplate?.kind === 'twilio/quick-reply' ||
      whatsappTemplate?.kind === 'twilio/call-to-action'
    ) {
      return whatsappTemplate.actions.map((a) => ({ text: a.title }))
    } else if (whatsappTemplate?.kind === 'twilio/list-picker') {
      return [{ kind: 'list-picker', text: whatsappTemplate.params.button }]
    } else {
      return []
    }
  })()

  useEffect(() => {
    act.campaignBuilder.loadWhatsAppTemplates()
  }, [])

  useEffect(() => {
    act.campaignBuilder.renderTemplate()
  }, [whatsappTemplateId, JSON.stringify(interpolations)])

  const body = whatsappTemplate?.body
  let prettyBody = '<p>' + (body || '').replaceAll('\n', '</p><p>') + '</p>'
  for (const [slug, tag] of Object.entries(mergeTags)) {
    prettyBody = prettyBody.replaceAll(
      `{{${slug}}}`,
      `<span class="merge-tag">${tag}</span>`
    )
  }

  function clearTemplate() {
    act.campaign.upsertTextMessageBlast({
      body: null,
      whatsappTemplateId: null,
      interpolations: {},
    })
  }

  function selectTemplate(template) {
    const defaultInterpolations = Object.keys(template.interpolations || {}).reduce((acc, key) => {
      acc[key] = ''
      return acc
    }, {})
    act.campaign.upsertTextMessageBlast({
      body: template.body,
      whatsappTemplateId: template.id,
      interpolations: defaultInterpolations,
      defaultInterpolations: template.interpolations,
    })
  }

  return (
    <Step
      name="Content"
      editor="text"
      complete={complete}
      canClickFinish={complete}
      shortDescriptor={
        <WhatsAppContentShortDescriptor complete={complete} body={prettyBody} />
      }
    >
      <div>
        {!whatsappTemplateId && (
          <>
            <p className="bold">Choose a template</p>
            {activeWhatsAppTemplates.length > 0 && (
              <div className="whatsapp-templates">
                {activeWhatsAppTemplates.map((template) => {
                  return (
                    <div className="whatsapp-template" key={template.id}>
                      <div className="left">
                        <p className="bold no-margin-top no-margin-bottom template-slug">
                          <span className="ellipsis green"></span>
                          <b>{template.name}</b>
                        </p>
                      </div>
                      <div className="inner-box template-preview">
                        <p
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: template.body.replace(/\n/g, '<br/>'),
                          }}
                        ></p>
                        <a
                          onClick={() => selectTemplate(template)}
                          className="button primary small"
                        >
                          Use this template
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {activeWhatsAppTemplates.length === 0 && (
              <p>
                You currently don't have any WhatsApp templates set up. Please get in
                touch via support.
              </p>
            )}
          </>
        )}
      </div>

      {whatsappTemplate && (
        <div>
          <button className="button navigation" onClick={() => clearTemplate()}>
            <SvgIconsBack />
            <span>Back to templates</span>
          </button>

          <div className="edit-whatsapp-template">
            <div>
              <div className="whatsapp-template-header">
                <p className="bold no-margin-top no-margin-bottom">
                  <span className="ellipsis green"></span>
                  {whatsappTemplate?.name}
                </p>
                <SimpleCharacterCounter
                  content={renderedContent.body}
                  max={MAX_WHATSAPP_MESSAGE_LENGTH}
                />
              </div>

              <div className="main">
                <div className="left">
                  <div className="inner-box">
                    <p
                      className="no-margin-top no-margin-bottom"
                      dangerouslySetInnerHTML={{
                        __html: whatsappTemplate?.body?.replace(/\n/g, '<br/>'),
                      }}
                    ></p>
                  </div>

                  {editing && (
                    <div className="inner-box">
                      <p className="bold">Custom fields</p>
                      <p>Enter text or choose a dynamic tag for each custom field</p>

                      {templateRenderError ? (
                        <p className="error">{templateRenderError}</p>
                      ) : null}

                      <div className="fields">
                        {_.keys(interpolations).map((key, i) => <InterpolationField key={key} i={i} />)}
                      </div>
                    </div>
                  )}
                </div>

                <div className="preview">
                  <SmsPreview
                    name={'Test'}
                    content={renderedContent}
                    kind="whatsapp"
                    actions={actions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Step>
  )
}

function InterpolationField({ i }) {
  const act = useAct()

  const interpolations = useSel(s => s.campaign.textMessageBlast.interpolations)
  const defaultInterpolations = useSel(s => s.campaign.textMessageBlast.defaultInterpolations)
  const whatsappTemplateId = useSel(s => s.campaign.textMessageBlast.whatsappTemplateId)
  const whatsappTemplates = useSel(s => s.campaignBuilder.whatsAppTemplates)
  const whatsappTemplate = whatsappTemplates.find((t) => t.id === whatsappTemplateId)

  const mergeTags = useSel(s => s.campaignBuilder.mergeTags)
  const [keyPrefix, setKeyPrefix] = useState(0)
  const [isUploaded, setIsUploaded] = useState(false)

  const key = _.keys(interpolations)[i]
  const value = interpolations[key]

  const isMediaInterpolation = (number) => {
    if (!whatsappTemplate?.kind === 'twilio/media') return false

    // Whatsapp Template media URLs are expected to have an interpolation like {{1}}
    // starting with 1, not 0.
    // An interpolation will use media if its number matches one of these interpolations
    // and if the URL's domain matches the current CDN's.
    const url = (whatsappTemplate.params.media || []).find((url) => url.includes(`{{${number}}}`))
    return !!url
  }

  const enableUpload = (number) => {
    if (!isMediaInterpolation(number)) return false

    const url = (whatsappTemplate.params.media || []).find((url) => url.includes(`{{${number}}}`))
    return url && url.replace(`/{{${number}}}`, '') === window.ENV.CDN_DOMAIN // should be exact match <cdn_domain>/{{n}}
  }

  function upsertInterpolation(key, value) {
    act.campaign.upsertTextMessageBlast({
      interpolations: { ...interpolations, [key]: value },
    })
  }

  function handleFileUploaded([blobId], filename) {
    const uploadUrl = `rails/active_storage/blobs/proxy/${blobId}/${filename}`
    const keys = Object.keys(interpolations)
    const lastKey = keys[keys.length - 1]
    upsertInterpolation(lastKey, uploadUrl)
    setKeyPrefix((prev) => prev + 1) // force Pill editors to remount
  }

  useEffect(() => {
    if (value?.includes('rails/active_storage/blobs') && enableUpload(i + 1)){
      setIsUploaded(true)
    } else if (isUploaded) {
      setIsUploaded(false)
    }
  }, [value, i, isUploaded])

  function handleManualEntry() {
    upsertInterpolation(key, '')
  }

  return <div className="custom-field" key={i}>
    <div className="field-number">{key}</div>
    <div className="editor-container">
      { !isUploaded && <PillEditor
        condensed
        enableEmojis={!isMediaInterpolation(i + 1)}
        initialValue={interpolations[key]}
        onChange={(body) => upsertInterpolation(key, body)}
        availableTags={mergeTags}
        key={`${keyPrefix}-${i}`}
        placeholder={defaultInterpolations ? defaultInterpolations[key]: ''}
      /> }

      {enableUpload(i + 1) && (
        <div className="margin-top">
          <FileUploader
            existingUrl={interpolations[key]}
            onUploaded={handleFileUploaded} />
        </div>
      )}

      { isUploaded && <button className='button naked link' onClick={handleManualEntry}>Enter manually</button> }
    </div>
  </div>
}
