import useAct from '../useAct'
import BackButton from 'Shared/components/BackButton'
import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'
import JsonField from './JsonField'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

const Form = () => {
  const act = useAct()
  
  const {
    id,
    name,
    description,
    slug,
    bodyHtml,
    headHtml,
    stylesCss,
    fields,
    inUse,
  } = useSel((s) => s.flowTemplate)
  
  const { error } = useSel((s) => s.local)

  const [fieldsValid, setFieldsValid] = useState(true)

  const isValid = (() => {
    return name && fieldsValid
  })()
  
  const commit = () => {
    if (!isValid) {
      return Promise.resolve()
    }
    return act.flowTemplate.commit()
  }
  
  const remove = () => {
    if (inUse) {
      return Promise.resolve()
    }
    if (confirm('Are you sure?')) {
      act.flowTemplate.remove(id).then(() => (window.location.href = '/admin/settings/flow_templates'))
    }
  }
  
  return (
    <>
      <div className="header">
        <BackButton />
        <h1>Page Template: {name}</h1>
      </div>
      
      <div className="section">
        <div className="box">          
          <div className="input_container_name">
            <label className="margin-bottom half">Name</label>
            <input type='text' name='name' value={name} onChange={(e) => act.flowTemplate.upsert({name: e.target.value})} />
            {!name && (
              <p className="error">Name is required</p>
            )}
          </div>
          
          <div className="input_container_description margin-top">
            <label className="margin-bottom half">Description</label>
            <input type='text' name='description' value={description || ''} onChange={(e) => act.flowTemplate.upsert({description: e.target.value})} />
          </div>

          <div className="input_container_body_html margin-top">
            <label className="margin-bottom half">Body HTML</label>
            <CodeMirrorEditor
              value={bodyHtml}
              onChange={(val) => act.flowTemplate.upsert({bodyHtml: val})}
              mode='html'
            />
          </div>

          <div className="input_container_head_html margin-top">
            <label className="margin-bottom half">Head HTML</label>
            <CodeMirrorEditor
              value={headHtml}
              onChange={(val) => act.flowTemplate.upsert({headHtml: val})}
              mode='html'
            />
          </div>

          <div className="input_container_styles_css margin-top">
            <label className="margin-bottom half">Styles CSS</label>
            <CodeMirrorEditor
              value={stylesCss}
              onChange={(val) => act.flowTemplate.upsert({stylesCss: val})}
              mode='css'
            />
          </div>
          
          <div className="input_container_fields margin-top">
            <JsonField
              name="Fields"
              value={fields}
              onChange={(val) => act.flowTemplate.upsert({fields: val})}
              onValidityChange={(valid) => setFieldsValid(valid)}
              />
          </div>
          
          
          <div className="actions margin-top">
            <ServerWaitButton className='button primary' onClick={commit} disabled={!isValid}>Save</ServerWaitButton>
            { error ? (
              <div className="error">
                <p className="error">{error}</p>
              </div>
            ) : <></>}
          </div>
          
          <div className="box danger-zone margin-top">
              <h3 className="no-margin-top red">Danger zone</h3>
                { inUse ? (
                  <p className="smaller dark-grey no-margin-top">You can't delete this page template as it's currently in use.</p>
                ) : (
                  <p className="smaller dark-grey no-margin-top">If you delete this page template, it'll no longer be usable in pages.</p>
                )}
                <button className="button red" onClick={remove} disabled={inUse}>Delete</button>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Form
