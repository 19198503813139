import './Header.scoped.scss'

import { useAct } from 'FlowBuilder/store'
import BackLink from 'Shared/components/BackLink'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import TopNav from './TopNav'
import { toast } from 'react-toastify'

export default function Header({ }) {
  const act = useAct()

  const { name, publicUrl } = useSel((s) => s.builder?.flow || {})
  const id = useSel((s) => s.builder?.flow?.id)
  const isDirty = useSel((s) => s.local.isDirty)
  const validationErrors = useSel((s) => s.builder.validationErrors || [])

  const backLink = '/admin/flows'

  const handleSave = () => {
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => {
        toast.error(error)
      })
      return Promise.reject(new Error('Validation errors present'))
    }
    return Promise.resolve(act.builder.persistAll())
  }

  return (
    <>
      <header>
        <div>
          <div className="flex-center gap-10">
            <BackLink href={backLink} />{' '}
            <h1>{name}</h1>
          </div>
        </div>

        <div className="actions flex-center gap-12">
          <a className={`button tertiary ${isDirty ? 'disabled' : ''}`} target='_blank' href={isDirty ? '' : `${publicUrl}?forget=1`} rel="noreferrer">View</a>
          <ServerWaitButton className='button red' onClick={() => act.builder.load(id)} disabled={!isDirty}>Discard changes</ServerWaitButton>
          <ServerWaitButton className='button primary' onClick={handleSave}>Save</ServerWaitButton>
        </div>
      </header>
      <TopNav />
    </>
  )
}
