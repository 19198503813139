import {
  createEntityAdapter,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import reducers from './reducers'

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()

const store = configureStore({
  middleware,
  reducer: reducers,
  devTools: {
    name: 'Flow Wizard',
  },
})

export default store
