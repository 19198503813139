import PageContainer from './PageContainer'
import store from '../store'
import { Provider } from 'react-redux'

export default function FlowWizard(params) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PageContainer {...params} />
      </ErrorBoundary>
    </Provider>
  )
}

