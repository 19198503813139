import { createSlice } from '@reduxjs/toolkit'

const templateWizardSlice = createSlice({
  name: 'templateWizard',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
  },
}).reducer

const templatesSlice = createSlice({
  name: 'templates',
  initialState: [],
  reducers: {
    set: (s, a) => a.payload,
  },
}).reducer

const reducers = {
  templates: templatesSlice,
  templateWizard: templateWizardSlice
}

export default reducers
