import { createSlice } from '@reduxjs/toolkit'

const brandSlice = createSlice({
  name: 'brand',
  initialState: [],
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    set: (s, a) => a.payload,
  },
}).reducer

const local = createSlice({
  name: 'local',
  initialState: {
    error: null,
    loaded: false,
    dirty: false,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
}).reducer

const reducers = {
  local,
  brand: brandSlice
}

export default reducers
