import camelCase from 'camelcase'
import { useAct } from 'FlowBuilder/store'
import Loading from 'Shared/components/Loading'
import TextField from 'Shared/components/TextField'
import CheckboxField from 'Shared/components/CheckboxField'
import SelectField from 'Shared/components/SelectField'
import SurveyForm from './ActionForms/Survey/SurveyForm'

export default function Setup({ }) {
  const act = useAct()

  const {id, slug, settings} = useSel(s => s.builder?.flow || {})
  const errors = useSel(s => s.local.errors?.flow || {})
  const actions = useSel(s => _.values(s.actions.entities) || [])
  const surveyAction = actions.find(a => a.kind === 'survey')

  function settingUpdater(key) {
    return (e) => {
      act.builder.updateFlowSetting(key, e.target.value)
    }
  }

  const templateFields = useSel(s => 
    (s.builder.flow?.template?.fields || []).filter(f => f.setup)
  )

  const templateFieldValues = useSel(s => s.builder.templateFields)

  function settingUpdater(key) {
    return (value) => {
      act.builder.updateFlowSetting(key, value)
    }
  }

  if(!settings) {
    return <Loading />
  }

  return <><div className="box">
    <form>
      <h2 className="no-margin-top">Setup</h2>
      <p>Update the details of this flow.</p>

      <TextField 
        label="Page URL slug" 
        value={slug} 
        onChange={(value) => { act.builder.updateFlow('slug', value)}} 
        error={errors.slug}
      />

      <TextField label="Share title" value={settings.shareTitle} onChange={settingUpdater('shareTitle')} />

      <TextField label="Share description" value={settings.shareDescription} onChange={settingUpdater('shareDescription')} />

      <TextField label="Share image URL" value={settings.shareImageUrl} onChange={settingUpdater('shareImageUrl')} />

      {templateFields.map((field) => {
        const value = templateFieldValues[camelCase(field.slug)]
        const handleChange = (value) => act.builder.setTemplateField({slug: camelCase(field.slug), value})
        if(field.questionType === 'text') 
          return <TextField label={field.label} value={value} onChange={handleChange} />
        if(field.questionType === 'checkbox') 
          return <CheckboxField label={field.label} value={value} onChange={handleChange} />
        if(field.questionType === 'select')
          return <SelectField label={field.label} value={value} onChange={handleChange} options={field.options} />
        return null
      })}
    </form>
  </div>
  { surveyAction && <div className="box narrow margin-top">
    <SurveyForm actionId={surveyAction.id} />
  </div>}
  </>
}
