import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import reducers from './reducers'

const middleware = [...getDefaultMiddleware()]

const store = configureStore({
  middleware,
  reducer: reducers,
  devTools: {
    name: 'Brand Settings',
  },
})

export default store
