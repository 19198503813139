import { useAct } from 'FlowBuilder/store'
import SurveyAction from './SurveyAction'
import './Sidebar.scoped.scss'
import Button from 'Shared/components/Button'
import orderActions from 'FlowTaker/lib/orderActions'

const KIND_MAPPINGS = {
  'redirect': 'Redirect'
}

export default function Sidebar() {
  const act = useAct()
  const actions = useSel(s => _.values(s.actions.entities) || [])
  const orderedActions = orderActions(actions)
  const navItem = useSel(s => s.local.navItem)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  
  useEffect(() => {
    if (navItem === 'setup') {
      act.builder.selectAction(null)
    } else if (selectedActionId === null) {
      act.builder.selectAction(orderedActions[0]?.id)
    }
  }, [selectedActionId, navItem])

  return <div className="flow-sidebar">
    {orderedActions.map(action => <Action key={action.id} action={action} />)}

    { navItem === 'build' && <Button secondary onClick={() => act.local.setModal({kind: 'addAction'}) }>
      <SvgIconsPlus /> Add step
    </Button> }
  </div>
}

function Action({ action }) {
  const act = useAct()
  const { name, kind } = action
  const navItem = useSel(s => s.local.navItem)
  const selectedActionId = useSel(s => s.local.selectedActionId)
  const selected = selectedActionId === action.id

  if (kind === 'survey') {
    return <SurveyAction action={action} />
  }

  function remove(e) {
    e.stopPropagation()
    act.actions.markInactive(action.id)
  }

  return <div className={`box cursor-pointer ${selected ? 'dark-border' : ''} margin-bottom`} onClick={() => act.builder.selectAction(action.id)}>
    <div className='row justify-space-between'>
      <p className='title'>{KIND_MAPPINGS[kind] || name}</p>
      {navItem === 'build' && <div onClick={remove}><SvgIconsDelete /></div>}
    </div>
  </div>
}
