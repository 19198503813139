import React from 'react'
import Select, { components } from 'react-select'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const MultiValueContainer = props => {
  const { children, innerProps, data, selectProps } = props
  const { value } = selectProps
  const index = value.indexOf(data)
  
  return (
    <Draggable draggableId={data.value} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...innerProps}
        >
          <components.MultiValueContainer {...props}>
            {children}
          </components.MultiValueContainer>
        </div>
      )}
    </Draggable>
  )
}

const DraggableSelect = ({ options, value, onChange, ...props }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(value)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    
    onChange(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Select
              isMulti
              options={options}
              value={value}
              onChange={onChange}
              components={{
                MultiValueContainer
              }}
              {...props}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableSelect
