export default function templates(dis, store, client) {
  async function load(id) {
    return client.get(`/api/flows/available_templates`)
      .then(({ data }) => {
        dis({ type: 'templates/set', payload: data })
      })
  }

  return { load }
}
