import { useAct } from 'FlowBuilder/store'
import './AddQuestion.scoped.scss'

export const questionTypes = {
  instruction: {
    name: 'Instruction',
    description: 'Add an instruction',
    icon: <SvgIconsQuote width="40" height="40" color="#F0C266" />,
    personalData: false
  },
  text: {
    name: 'Text',
    description: 'Add a question with a text answer',
    icon: <SvgIconsText />,
    personalData: false
  },
  multiple_choice: {
    name: 'Multiple Choice',
    description: 'Choose one option from a selection',
    icon: <SvgIconsMultipleSelect />,
    personalData: false
  },
  multiple_select: {
    name: 'Multiple Select',
    description: 'Choose multiple options',
    icon: <SvgIconsMultipleSelect />,
    personalData: false
  },
  opt_in_radio: {
    name: 'Opt-in Radio',
    description: 'Add an opt-in ask',
    icon: <SvgIconsOptIn />,
    personalData: false
  },
  checkbox: {
    name: 'Checkbox',
    description: 'A single checkbox',
    icon: <SvgIconsCircleTick />,
    personalData: false
  },
  name: {
    name: 'Name',
    description: 'Collect a name',
    icon: <SvgIconsText />,
    personalData: true
  },
  email: {
    name: 'Email',
    description: 'Collect an email',
    icon: <SvgIconsMail  width="40" height="40" color="#F0C266" />,
    personalData: true
  },
  postcode: {
    name: 'Postcode',
    description: 'Collect a postcode',
    icon: <SvgIconsText />,
    personalData: true
  },
  phone_number: {
    name: 'Phone Number',
    description: 'Collect a phone number',
    icon: <SvgIconsPhone  width="40" height="40" color="#F0C266" />,
    personalData: true
  },
  score: {
    name: 'Score',
    description: 'Score',
    icon: <SvgIconsNumber />,
    personalData: false
  },
  file: {
    name: 'File Upload',
    description: 'Upload an image or other file',
    icon: <SvgIconsText />,
    personalData: false
  },
  date: {
    name: 'Date',
    description: 'Enter a date',
    icon: <SvgIconsText />,
    personalData: false
  }
}

export default function AddQuestion() {
  const act = useAct()
  const actionId = useSel(s => s.local.selectedActionId)
  const surveyId = useSel(s => s.builder.flow?.actions?.find(a => a.id === actionId)?.surveyId)
  const pageNumber = useSel(s => s.local.modal.pageNumber)

  function addQuestion(questionType) {
    act.questions.add({ questionType, surveyId, pageNumber, settings: {} })
    act.local.closeModal()
  }

  const personalDataButtons = Object.entries(questionTypes)
    .filter(([_, config]) => config.personalData)
    .map(([type, config]) => ({
      type,
      label: config.name,
      icon: config.icon,
      description: config.description
    }))

  const regularButtons = Object.entries(questionTypes)
    .filter(([_, config]) => !config.personalData)
    .map(([type, config]) => ({
      type,
      label: config.name,
      icon: config.icon,
      description: config.description
    }))

  const renderButtons = (buttons) => (
    <>
      {buttons.map(({ type, label, icon, description }) => (
        <div key={type} className="add-question" onClick={() => addQuestion(type)}>
          <div className="inner cursor-pointer">
            <div className='icon'>{icon}</div>
            <p className="label">{label}</p>
            <p className="description">{description}</p>
          </div>
        </div>
      ))}
    </>
  )

  return (
    <div className="space-y-8">
      <section>
        <h3 className="no-margin-top">Personal details</h3>
        {renderButtons(personalDataButtons)}
      </section>
      
      <section>
        <h3>Questions</h3>
        {renderButtons(regularButtons)}
      </section>
    </div>
  )
}