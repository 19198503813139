const columns = [
  {
    data: 'name',
    width: '35%',
    className: 'bold',
    render: (data, type, row, meta) => {
      return `<a href="/admin/flows/${row.DT_RowId}/overview">${data}</a>`
    },
  },
  {
    data: 'wizard_name',
    width: '25%',
  },
  {
    data: 'status',
    width: '15%',
    render: function(data, type, row, meta) {
      function getClass() {
        const status = row['status']
        if (status === 'live') return 'accent'
        if (status === 'closed') return 'blue-100'

        return 'purple-60'
      }

      var status = row['status']

      return `<span class="bold ${getClass()} capitalize">${status}</span>`
    },
  },
  { data: 'updated_at', width: '25%'}
]

const headers = ['Name', 'Template', 'Status', 'Last edited']

const filters = [
  {
    name: 'Status', column: 2, kind: 'radio', options: [
      { value: 'draft', label: 'Draft' },
      { value: 'live', label: 'Live' },
      { value: 'paused', label: 'Paused' },
      { value: 'closed', label: 'Closed' }
    ]
  },
  { name: 'Using a template', kind: 'async', entity: 'flow_template' },
]

const sorters = [
  { column: 3, label: 'Last edited', order: 'desc' },
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' }
]

const order = [[ 3, "desc" ]]

export { columns, headers, filters, sorters, order }
