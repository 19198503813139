import FilterParameter from './FilterParameter'
import './Filter.scoped.scss'

const SubFilter = ({ ruleId, subFilter }) => {
  const act = useAct()
  const { parameters } = useSel((s) => s.rules.entities[ruleId])
  const [expanded, setExpanded] = useState(false)

  // decide whether this sub-filter has been added by checking if its parameters exist
  useEffect(() => {
    const isSubFilterActive = Object.keys(subFilter.fields).some((field) => {
      return parameters[field] !== undefined
    })
    setExpanded(isSubFilterActive)
  }, [JSON.stringify(parameters)])

  // activate this sub-filter by adding its parameters to the rule
  function activate() {
    const defaults = {}
    for (var key in subFilter.fields) {
      defaults[key] = ''
    }
    act.search.upsertFilterParameters(ruleId, defaults)
  }

  // remove this sub-filter by removing its parameters
  function remove() {
    act.search.removeFilterParameters(ruleId, Object.keys(subFilter.fields))
  }

  if (!expanded) {
    return null // Don't render anything in the expanded section if not expanded
  }

  return (
    <div className="sub-filter">
      <div className="left components">
        <p className="label bold">{subFilter.presentation.name}</p>
        {subFilter.presentation.layout.map(({ type, text, slug }) => {
          if (type === 'label') {
            return <p className="label">{text}</p>
          } else if (type === 'parameter') {
            return (
              <FilterParameter
                id={ruleId}
                name={_.camelCase(slug)}
                fields={subFilter.fields}
              />
            )
          }
        })}
      </div>
      <div className="right">
        <a className="link naked blue-link" onClick={remove}>
          Remove
        </a>
      </div>
    </div>
  )
}

const SubFilterButton = ({ ruleId, subFilter, expanded }) => {
  const act = useAct()

  function activate() {
    const defaults = {}
    for (var key in subFilter.fields) {
      defaults[key] = ''
    }
    act.search.upsertFilterParameters(ruleId, defaults)
  }

  if (expanded) {
    return null // Don't render the button if the filter is expanded
  }

  return (
    <a className="button small secondary margin-right" onClick={activate}>
      {subFilter.presentation.name}
    </a>
  )
}

export default ({ id }) => {
  const { filter: filterName } = useSel((s) => s.rules.entities[id])
  const availableFilters = useSel((s) => s.availableFilters)
  const filter = availableFilters[filterName]
  const { parameters } = useSel((s) => s.rules.entities[id])
  const hasSubFilters = Object.keys(filter.subFilters).length > 0

  if (!filter) return null

  // Calculate which subfilters are expanded
  const expandedSubFilters = {}
  if (hasSubFilters) {
    Object.keys(filter.subFilters).forEach((subFilterName) => {
      const subFilter = filter.subFilters[subFilterName]
      expandedSubFilters[subFilterName] = Object.keys(subFilter.fields).some(
        (field) => parameters[field] !== undefined
      )
    })
  }

  return (
    <div className="filter">
      <div className="components">
        {filter.presentation.layout.map(({ type, text, slug }) => {
          if (type === 'label') {
            return <p className="label bold">{text}</p>
          } else if (type === 'parameter') {
            return (
              <FilterParameter id={id} name={_.camelCase(slug)} fields={filter.fields} />
            )
          }
        })}
      </div>
      {hasSubFilters && (
        <>
          <div className="sub-filters expanded">
            {Object.keys(filter.subFilters).map((subFilterName) => {
              const subFilter = filter.subFilters[subFilterName]
              return (
                <SubFilter
                  key={subFilterName}
                  ruleId={id}
                  subFilter={subFilter}
                />
              )
            })}
          </div>
          <div className="sub-filters buttons">
            {Object.keys(filter.subFilters).map((subFilterName) => {
              const subFilter = filter.subFilters[subFilterName]
              return (
                <SubFilterButton
                  key={subFilterName}
                  ruleId={id}
                  subFilter={subFilter}
                  expanded={expandedSubFilters[subFilterName]}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
