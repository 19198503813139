import { createSlice } from '@reduxjs/toolkit'

import { actions as actionActionsF } from './actions'
import { actions as localActionsF } from './local'
import { actions as surveyActionsF } from './surveys'
import orderActions from 'FlowTaker/lib/orderActions'
import { toast } from 'react-toastify'

const reducer = createSlice({
  name: 'builder',
  initialState: {
    templateFields: {},
    validationErrors: []
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    setTemplateField: (s, a) => {
      s.templateFields[a.payload.slug] = a.payload.value
    },
    updateFlowSetting: (s, a) => {
      s.flow.settings[a.payload.key] = a.payload.value
    },
    updateFlow: (s, a) => {
      s.flow[a.payload.key] = a.payload.value
    },
    setValidationErrors: (s, a) => {
      s.validationErrors = a.payload
    },
  },
}).reducer

const actions = (dis, store, restClient) => {
  const actionActions = actionActionsF(dis, store, restClient)
  const localActions = localActionsF(dis, store, restClient)
  const surveyActions = surveyActionsF(dis, store, restClient)

  function load(flowId) {
    return restClient.get(`/api/flows/${flowId}`)
      .then(({ data: flow }) => {
        handleFlowData(flow)
        selectAction(flow.actions.find(a => a.isStart)?.id)
      })
  }

  function handleFlowData(flow) {
    // Put anything editable in a different part of the state
    const templateFields = flow.templateFields
    dis({ type: 'builder/upsert', payload: { flow, templateFields } })
    flow.actions.forEach(a => {
      if (a.kind === 'survey') {
        surveyActions.load(a.surveyId)
      }
    })

    dis({ type: 'actions/setAll', payload: flow.actions })

    localActions.setDirty(false)
  }

  function loadMergeTags() {
    // create a new axios client which bypasses the case conversion - we want to
    // load merge tags in snake case
    return restClient.get(`/api/render/available_merge_tags`)
      .then(({ data: mergeTags }) => {
        dis({ type: 'builder/upsert', payload: { mergeTags } })
      })
  }

  function selectAction(actionId, { pageNumber } = {}) {
    const state = store.getState()
    if (state.local.navItem === 'setup') {
      actionId = null 
    }
    if (typeof(pageNumber) === 'undefined') {
      if (state.local.selectedActionId === actionId) {
        var selectedPageNumber = state.local.selectedPageNumber
      } else {
        var selectedPageNumber = 1
      }
    } else {
      var selectedPageNumber = pageNumber
    }

    dis({ type: 'local/upsert', payload: { selectedActionId: actionId, selectedPageNumber } })
  }

  function selectQuestion(questionId) {
    dis({ type: 'local/upsert', payload: { selectedQuestionId: questionId } })
  }

  function setTemplateField(payload) {
    localActions.setDirty(true)
    dis({ type: 'builder/setTemplateField', payload })
  }

  function updateFlowSetting(key, value) {
    localActions.setDirty(true)
    dis({ type: 'builder/updateFlowSetting', payload: { key, value } })
  }

  function updateFlow(key, value) {
    dis({ type: 'builder/updateFlow', payload: { key, value } })
  }

  function persistAll() {
    const state = store.getState()
    const entities = state.actions.entities
    const orderedActions = orderActions(_.values(entities))
    const currentActionId = state.local.selectedActionId
    const currentActionsIndex = orderedActions.findIndex(a => a.id === currentActionId)
    const actions = _.values(state.actions.entities)

    const promises = []
    actions.forEach(action => {
      if (action.kind === 'survey') {
        promises.push(surveyActions.persistQuestions(action.surveyId))
      }
    })
    promises.push(persistFlow())

    return Promise.all(promises)
      .then(() => localActions.setDirty(false))
      .then(() => {
        const currentState = store.getState()
        const entities = currentState.actions.entities
        const orderedActions = orderActions(_.values(entities))
        const currentActionId = currentState.local.selectedActionId

        if (!entities[currentActionId]) {
          const lastActionId = orderedActions[currentActionsIndex]?.id
          return selectAction(lastActionId)
        }
        return selectAction(currentActionId)
      })
  }

  const persistFlowFields = ['name', 'slug', 'settings']

  function persistFlow() {
    const state = store.getState()
    const templateFields = state.builder.templateFields
    const flow = { ..._.pick(state.builder.flow, persistFlowFields), templateFields }
    const settings = state.builder.flow.settings
    const actions = _.values(state.actions.entities)
    const flowId = state.builder.flow.id

    return restClient.put(`/api/flows/${flowId}`, { flow, templateFields, settings, actions })
      .then((response) => {
        dis({ type: 'local/clearErrors', payload: 'flow' })
        handleFlowData(response.data)
      }).catch((error) => {
        toast.error('Failed to save. Please check the details you entered')
        dis({ type: 'local/upsert', payload: { errors: error.response.data.errors } })
      })
  }

  function setValidationErrors(errors) {
    dis({ type: 'builder/setValidationErrors', payload: errors })
  }



  return {
    load,
    loadMergeTags,
    selectAction,
    selectQuestion,
    setTemplateField,
    updateFlowSetting,
    updateFlow,
    persistAll,
    setValidationErrors,
  }
}

export { reducer, actions }
export default { reducer, actions }
