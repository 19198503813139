import SurveyPageForm from './Survey/SurveyPageForm'

export default function SurveyActionForm({ actionId, pageNumber }) {
  const surveyId = useSel(s => s.builder.flow?.actions?.find(a => a.id === actionId)?.surveyId)
  const refreshKey = useSel(s => s.local.refreshKey)
  
  return <>
    {pageNumber && <div className='box scroll-y margin-bottom flex-scroll-fix grow'>
      <SurveyPageForm surveyId={surveyId} pageNumber={pageNumber} key={refreshKey} />
    </div>}
  </>
}
