import { Provider } from 'react-redux'
import store from '../store'
import Form from './Form'

export default function ManageBrandSettings() {
  return (
    <Provider store={store}>
      <Form /> 
    </Provider>
  )
}
